<template>
  <div class="change_bg">

    <div class="modal_bg" :class="{'active' : isActive_m}">
      <div class="modal_content">
        <div class="m_card">
          <div class="m_card_main">
            <div>
              <button class="exit_modal" @click="clouseM()">

                {{$store.state.language.lang[73]}}
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.03125 0.861328L15.2526 15.08M1.03125 15.08L15.2526 0.861328" stroke="#1DBB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="m_card_bottom">
            <div class="m_b">
              <div class="m_b_data">{{dataitem.obligation}}</div>
            </div>
            <div class="m_b">
              <div class="m_b_data">{{dataitem.requirement}}</div>
            </div>
            <div class="m_b">
              <div class="m_b_data">{{dataitem.work_condition}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">

              {{$store.state.language.lang[19]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-12">

            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[19]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/agency/management" class="menu_drops">
                  {{$store.state.language.lang[10]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/central/office" class="menu_drops">
                  {{$store.state.language.lang[9]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/structure" class="menu_drops">
                  {{$store.state.language.lang[11]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/council" class="menu_drops">
                  {{$store.state.language.lang[12]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/territorial" class="menu_drops">
                  {{$store.state.language.lang[13]}}
                </router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/regulations" class="menu_drops">
                  {{$store.state.language.lang[15]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/organization" class="menu_drops">
                  {{$store.state.language.lang[16]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/activity" class="menu_drops">
                  {{$store.state.language.lang[17]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/museum" class="menu_drops">
                  {{$store.state.language.lang[18]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/vacancy" class="menu_drops">
                  {{$store.state.language.lang[19]}}
                </router-link>
              </div>

            </div>
          </div>
          <div class="col-xl-9 col-12">
            <div class="m_body_title">
              <h2>
                {{$store.state.language.lang[19]}}</h2>
            </div>
            <MediaSide :name="$store.state.language.lang[19]"></MediaSide>
<!--            <div>-->
<!--              <div class="dec_input_group">-->
<!--                <div class="search_inputs">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                          d="M9.09091 1.81818C5.07429 1.81818 1.81818 5.07429 1.81818 9.09091C1.81818 13.1075 5.07429 16.3636 9.09091 16.3636C13.1075 16.3636 16.3636 13.1075 16.3636 9.09091C16.3636 5.07429 13.1075 1.81818 9.09091 1.81818ZM0 9.09091C0 4.07014 4.07014 0 9.09091 0C14.1117 0 18.1818 4.07014 18.1818 9.09091C18.1818 11.2739 17.4124 13.2771 16.1299 14.8443L19.7337 18.4481C20.0888 18.8031 20.0888 19.3787 19.7337 19.7337C19.3787 20.0888 18.8031 20.0888 18.4481 19.7337L14.8443 16.1299C13.2771 17.4124 11.2739 18.1818 9.09091 18.1818C4.07014 18.1818 0 14.1117 0 9.09091Z"-->
<!--                          fill="#01011C"/>-->
<!--                  </svg>-->
<!--                  <input type="text" :placeholder="$store.state.language.lang[65]">-->
<!--                </div>-->
<!--                <div class="buttons_search">-->
<!--                  <button>Search</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="dec_text">-->
<!--              Количество документов:{{$store.state.vacancy.count}}-->
<!--            </div>-->
            <div class="row">
              <div class="col-xl-6 col-12" v-for="(item,index) in $store.state.vacancy.results" :key="index">
                <div class="vacancy_card">
                <div>
                  <div class="vacancy_title">{{item.title}}</div>
                  <div class="vacancy_card_description" v-html="item.description">
                </div>
                  </div>
                  <div class="vacancy_card_footer vc_card">
                    <div class="vacancy_date">
                          <div class="vacancy_date_title">{{$store.state.language.lang[55]}}:</div>
                         <div class="vacancy_date_body">{{new Date(item.created_on).toLocaleDateString()}}</div>
                    </div>
                    <div class="vacancy_buttons vc_btn">
                      <button @click="myFunck(item=item);isActive_m=true">

                        {{$store.state.language.lang[32]}}

                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.808594 7.00097V1.66797M0.808594 1.66797H6.14159M0.808594 1.66797L7.47459 8.33397M0.808594 14.999V20.332M0.808594 20.332H6.14159M0.808594 20.332L7.47459 13.666M19.4746 7.00097V1.66797M19.4746 1.66797H14.1416M19.4746 1.66797L12.8086 8.33397M19.4746 14.999V20.332M19.4746 20.332H14.1416M19.4746 20.332L12.8086 13.666" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pagenation">
              <paginate
                  :page-count="$store.state.vacancy.total_pages"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSide from '../MediaSide'

export default {
  components: {
    MediaSide,
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      dataitem:{}
    }
  },
  mounted() {
    this.$store.dispatch('vacancy',1)
  },
  methods: {
    clickCallback (pageNum){

      this.$store.dispatch('vacancy',pageNum)
    },
    open_modal() {

    },
    myFunck(item=item){
      this.dataitem=item
      this.isActive_m=true
      if(this.isActive_m==true){
        document.body.classList.add('open_modal')
      }
    },
    clouseM(){
      this.isActive_m=false
      if(this.isActive_m==false){
        document.body.classList.remove('open_modal')
      }
    },
  }
}
</script>
<style>
.vc_card{
  width: 90%;
}
.vc_btn{
  right: 20px;
}
</style>